<template>
	<div>
		<Header ref="Header" />
		<img :src="menuPic" class="Big-title" />
		<div class="container">
			<div class="personal-list row">
				<div class="personal-list-left">
					<div class="index-sort-tiltle personal-sort-tiltle">个人中心</div>
					<div class="index-sort-list">
						<div v-for="(item,index) in nav">
							<div @click="sortSwitch(index)" :class="sortIndex==index?'index-sort-item2':'index-sort-item'">
								{{item.name}}
							</div>
							<img class="index-sort-boder" v-if="sortIndex==index" src="../../public/images/boder_01.png" />
						</div>
					</div>
				</div>
				<div class="personal-list-right">
					<div class="personal-right-order">
						<div class="personal-order-nav flex">
							<div v-for="(item,index) in orderNav" :key="index">
								<div @click="oderNavSwitch(index)" class="jump"
									:class="oderNavIndex==index?'personal-order-nav-item1':'personal-order-nav-item'">
									{{item.name}}
								</div>
							</div>
						</div>
						<div v-for="(item,index) in list " :key="index">
							<div class="mypublish-item">
								<div class="flex mypublish-top-line">
									<div class="mypublish-time">{{item.createTime}}</div>
									<div v-if="item.status>0 && item.status<4" class="mypublish-status">待确认</div>
									<div v-if="item.status==4" class="mypublish-status">已完成</div>
									<div v-if="item.status>4 && item.status<=6" class="mypublish-status">已取消</div>
								</div>
								<div class="mypublish-line flex">
									<div class="mypublish-title">公司名称</div>
									<div class="mypublish-text">{{item.company}}</div>
								</div>
								<div class="mypublish-line flex">
									<div class="mypublish-title">需求城市</div>
									<div class="mypublish-text">{{item.city}}</div>
								</div>
								<div class="mypublish-line flex">
									<div class="mypublish-title">活动类型</div>
									<div class="mypublish-text">{{item.activitiesTypeName}}</div>
								</div>
								<div class="mypublish-line flex">
									<div class="mypublish-title">联系电话</div>
									<div class="mypublish-text">{{item.createBy}}</div>
								</div>
							</div>
						</div>
						<div v-if="list.length==0" class="mypublish-tips">
							暂无数据哦~
						</div>
						<el-pagination
							style="margin-top: 50px;"
							class="bar-switch"
							background
							layout="prev, pager, next, jumper, total"
							:page-size="postData.pageSize"
							:total="Total"
							@current-change="choosePage">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/components/header.vue';
	import store from '@/store'
	import {
		mapState
	} from 'vuex';
	export default {
		components: {
			Header
		},
		data() {
			return {
				menuPic: '',
				sortIndex: 3,
				oderNavIndex: 0,
				Total:0,
				postData:{
					pageNum:1,
					pageSize:10,
				},
				nav: [
					{name: '个人信息',
						href: 'personal'
					},
					{
						name: '发布需求',
						href: 'publish'
					},
					{
						name: '我的订单',
						href: 'myOrder'
					},
					{
						name: '我的发布',
						href: 'myPublish'
					},
					{
						name: '我的收藏',
						href: 'myCollect'
					},
					{
						name: '我的消息',
						href: 'myNews'
					},
					{
						name: '意见反馈',
						href: 'feedback'
					},
				],
				orderNav: [{
						name: '全部订单',
						status: [0]
					},
					{
						name: '待确认',
						status: [1, 2, 3]
					},
					{
						name: '已完成',
						status: [4]
					},
					{
						name: '已取消',
						status: [5, 6]
					},
				],
				list:[],
			}
		},
		computed: {
			...mapState(['userInfo']),
		},
		mounted() {
			this.$refs.Header.setIndex(-1);
			// 获取菜单图片
			this.$api.getMenuPic('发布需求').then(res => {
				console.log(res, '菜单图片');
				this.menuPic = res.data[0].picture;
			})
			// 获取发布列表
			this.getPubulishLsit();
			console.log(this.userInfo, '用户信息')
		},
		methods: {
			sortSwitch(index) {
				this.sortIndex = index;
				this.$router.push({
					path: this.nav[index].href
				});
			},
			oderNavSwitch(index) {
				this.oderNavIndex = index;
				if (index == 0) {
					delete this.postData.status;
				} else {
					this.postData.status = this.orderNav[index].status.toString();
				}
				this.getPubulishLsit();
			},
			// 选择分页
			choosePage(num){
				this.postData.pageNum=num;
				this.getPubulishLsit();
			},
			getPubulishLsit(){
				console.log(this.postData);
				this.$api.myPubulish(this.postData).then(res=>{
					console.log(res,'发布列表');
					this.list=res.rows;
					this.Total=parseInt(res.total);
				})
			}
		},
	};
</script>
<style scoped>
</style>
